import React from 'react';
import Layout from '../components/layout/Layout';
import Hero from "../components/Hero";
import { Helmet } from 'react-helmet';
import ShareImg from "../images/share.png";

export default () => (
  <Layout>
    <Hero
      coverImage="https://images.unsplash.com/photo-1587560699334-bea93391dcef?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
      primaryContent={
        <span>Contact Us</span>
      }
    />
    <section class="text-gray-700 body-font">
      <form name="contact" class="container px-5 py-24 mx-auto" netlify-honeypot="bot-field" action="/thank-you" netlify>
       <input type="hidden" name="bot-field" />
       <input type="hidden" name="form-name" value="contact" />
        <div class="flex flex-col text-center w-full mb-12">
        </div>
        <div class="md:w-3/4 mx-auto">
          <div class=" md:flex flex-wrap -m-2">
            <div class="p-2 md:w-1/2">
              <div>
                <label for="name" class="leading-7 text-sm text-gray-600">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-600 text-xl outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 md:w-1/2">
              <div>
                <label for="email" class="leading-7 text-sm text-gray-600">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-600 text-xl outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 w-full">
              <div>
                <label for="message" class="leading-7 text-sm text-gray-600">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  class="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-600 h-32 text-xl outline-none text-gray-700 py-2 px-4 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div class="p-2 w-full">
              <button type="submit" class="flex mx-auto text-white bg-gray-900 border-0 py-2 px-8 focus:outline-none hover:bg-red-700 rounded text-lg">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </section>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Contact Us | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
);
